<template>
  <!-- 添加新闻 -->
  <div class="positionT0L0">
    <a-form-model
      ref="ruleForm"
      :model="form"
      :rules="rules"
      :label-col="{ span: 2 }"
      :wrapper-col="{ span: 15 }"
      labelAlign="left"
    >
      <a-form-model-item label="新闻标题" prop="title">
        <a-input v-model="form.title" :maxLength="20">
          <div slot="addonAfter">{{ form.title.length }}/20</div>
        </a-input>
      </a-form-model-item>
      <a-form-model-item label="新闻图片" prop="imageUrl">
        <CropperUpload
          avatarTextTop="推荐使用260*195px,JPG.PNG.JPEG格式"
          avatarTextBottom="图片小于2M"
          :imageUrl="form.imageUrl"
          :options="imageUrlOptions"
          @avatarfn="imageUrlFn"
        />
      </a-form-model-item>
      <a-form-model-item label="发布时间" prop="occurrenceTime">
        <a-date-picker
          v-model="form.occurrenceTime"
          format="YYYY-MM-DD HH:mm:ss"
          placeholder="选择发布时间"
        />
      </a-form-model-item>
      <a-form-model-item label="简介" prop="brief">
        <a-textarea
          placeholder="请输入简介"
          v-model="form.brief"
          :maxLength="200"
          :rows="5"
        />
        <span class="form_span">{{ form.brief.length }}/200</span>
      </a-form-model-item>

      <a-form-model-item label="新闻详情" prop="description">
        <Ueditor :content="form.description" @editorFn="editorFn" />
      </a-form-model-item>
    </a-form-model>
    <FooterToolBar :collapsed="sideCollapsed">
      <a-button class="margin_right60" @click="() => $router.go(-1)">
        返回
      </a-button>
      <a-button type="primary" :disabled="keepDis" @click="keepClick">
        保存
      </a-button>
    </FooterToolBar>
  </div>
</template>

<script>
import { NewsAddApi, NewsUpdApi, NewsDetailpi } from "@/request/api/operate";
import CropperUpload from "@/components/file/CropperUpload.vue";
import Ueditor from "@/components/ueditor/ueditor.vue";
import FooterToolBar from "@/components/FooterToolbar/FooterToolBar.vue";
import { message } from "ant-design-vue";
import { baseMixin } from "@/store/app-mixin";
export default {
  mixins: [baseMixin],
  components: { CropperUpload, Ueditor, FooterToolBar },
  created() {
    let id = this.$route.params.id;
    if (id != 0) {
      this.form.id = id;
      NewsDetailpi({ id: id }).then(({ code, data }) => {
        if (code == 200) {
          this.form = data;
        }
      });
    }
  },
  data() {
    return {
      form: {
        id: "",
        title: "",
        imageUrl: "",
        description: "",
        occurrenceTime: "",
        brief: "",
      },
      rules: {
        title: [
          { required: true, message: "输入的内容不能为空", trigger: "blur" },
        ],
        imageUrl: [{ required: true, message: "请上传新闻图片" }],
        occurrenceTime: [
          { required: true, message: "请选择发布时间", trigger: "change" },
        ],
        description: [{ required: true, message: "请填写新闻详情" }],
        brief: [
          { required: true, message: "输入的内容不能为空", trigger: "blur" },
        ],
      },
      imageUrlOptions: {
        autoCrop: true, //是否默认生成截图框
        autoCropWidth: 260, //默认生成截图框宽度
        autoCropHeight: 195, //默认生成截图框高度
        fixedBox: true, //是否固定截图框大小 不允许改变
        previewsCircle: false, //预览图是否是原圆形
        outputType: "png",
        title: "修改图片",
      },
      keepDis: false,
    };
  },
  methods: {
    imageUrlFn(obj) {
      this.form.imageUrl = obj.url;
    },
    // 富文本的函数
    editorFn(value) {
      this.form.description = value;
    },
    keepClick() {
      this.keepDis = true;
      this.$refs["ruleForm"].validate((result) => {
        if (result) {
          if (!this.form.id) {
            NewsAddApi(this.form).then(({ code }) => {
              if (code == 200) {
                message.success("新增成功");
                this.keepDis = false;
                this.$router.push(`/operate/newsCenter`);
              } else {
                setTimeout(() => {
                  this.keepDis = false;
                }, 3000);
              }
            });
          } else {
            NewsUpdApi(this.form).then(({ code }) => {
              if (code == 200) {
                message.success("修改成功");
                this.keepDis = false;
                this.$router.push(`/operate/newsCenter`);
              } else {
                setTimeout(() => {
                  this.keepDis = false;
                }, 3000);
              }
            });
          }
        } else {
          message.error("请正确填写表单");
          setTimeout(() => {
            this.keepDis = false;
          }, 3000);
        }
      });
    },
  },
};
</script>

<style lang="less" scoped></style>
